// react
import React from "react";

// vendors
import Axios, { AxiosInstance, CancelToken } from "axios";

// core
import sessionExpired from "./Core/sessionExpired";

// utils
import { getItem } from "./utlis/storage";

// service;
import { ADMIN } from "./Redux/Services/admin.service";
import { Button } from "@mui/material";

// --------------------------------------------------------------------------------

// const apiBase = "https://api.xcpep.com/";
// const apiBase = "https://api-tvs.xcpep.com/";
// const apiBase = "https://api-hmie.xcpep.com/";
// const apiBase = "https://api-daimler.xcpep.com/";
// const apiBase = "https://api-qa.xcpep.com/";
// const apiBase = "https://api-stage2.xcpep.com/";
// const currentUrl = window.location.href;
// const getApiBAse = () => {
//   let Apiurl = "";
//   if (typeof currentUrl !== "undefined") {
// if(currentUrl.includes("app.xcpep.com")){
//   Apiurl= "https://api.xcpep.com/";
// }
//   if (currentUrl.includes("tvsm.xcpep.com")) {
//     Apiurl = "https://api-tvsm.xcpep.com/";
//   } else if (currentUrl.includes("daimler.xcpep.com")) {
//     Apiurl = "https://api-dam.xcpep.com/";
//   } else if (currentUrl.includes("app.xcpep.com")) {
//     Apiurl = "https://api.xcpep.com/";
//   } else if (currentUrl.includes("mnm.xcpep.com")) {
//     Apiurl = "https://api-mnm.xcpep.com/";
//   } else if (currentUrl.includes("hmie.xcpep.com")) {
//     Apiurl = "https://api-hmie.xcpep.com/";
//   } else if (currentUrl.includes("bal.xcpep.com")) {
//     Apiurl = "https://api-ba.xcpep.com/";
//   } else if (currentUrl.includes("ae.xcpep.com")) {
//     Apiurl = "https://api-ae.xcpep.com/";
//   } else if (currentUrl.includes("olaelectric.xcpep.com")) {
//     Apiurl = "https://api-ol.xcpep.com/";
//   } else if (currentUrl.includes("mlmm.xcpep.com")) {
//     Apiurl = "https://api-mlmm.xcpep.com/";
//   } else if (currentUrl.includes("subscription.xcpep.com")) {
//     Apiurl = "https://api-subscription.xcpep.com/";
//   } else if (currentUrl.includes("mck.xcpep.com/")) {
//     Apiurl = "https://api-mck.xcpep.com/";
//   } else if (currentUrl.includes("av.xcpep.com/")) {
//     Apiurl = "https://api-av.xcpep.com/";
//   } else if (currentUrl.includes("cnhi.xcpep.com/")) {
//     Apiurl = "https://api-cnhi.xcpep.com/";
//   } else if (currentUrl.includes("tm.xcpep.com/")) {
//     Apiurl = "https://api-tm.xcpep.com/";
//   } else if (currentUrl.includes("ucal.xcpep.com/")) {
//     Apiurl = "https://api-ucal.xcpep.com/";
//   } else if (currentUrl.includes("mgi.xcpep.com/")) {
//     Apiurl = "https://api-mgi.xcpep.com/";
//   }
// }
//   return Apiurl;
// };


const apiBase = "https://api-stage2.xcpep.com/";     

// --------------------------------------------------------------PEs------------------

class api {
  fetch(arg0: string, data: FormData, arg2: number) { }
  private _apiBase: string;
  private axios: AxiosInstance;
  private timeout: number = 10000;

  constructor(apiBase: string) {
    this._apiBase = apiBase;
    this.axios = Axios.create({
      baseURL: this.apiBase,
      timeout: this.timeout,
    });
    // this.axios.defaults.headers.common["Content-Type"] = "application/json";
    this.axios.defaults.headers.post["Content-Type"] = "application/json";
    this.axios.interceptors.request.use(
      (req) => {
        let token = this.token;
        req["headers"]["Authorization"] = token ? `Token ${token}` : "";
        return req;
      },
      (e) => {
        return Promise.reject(e);
      }
    );
    this.axios.interceptors.response.use(
      (res) => {
        return res;
      },
      (e) => {
        if (e.response && e.response.status === 401) sessionExpired();
        return Promise.reject(e);
      }
    );
  }

  get apiBase() {
    return this._apiBase;
  }

  get staticBase() {
    return this._apiBase + "xcpep/static-files";
  }

  private get rememberMe() {
    return this.getItemLS("rememberMe");
  }

  get token() {
    return this.rememberMe ? this.getItemLS("token") : this.getItemSS("token");
  }

  private getItemSS = getItem.bind(sessionStorage);

  private getItemLS = getItem.bind(localStorage);

  private upload =
    (method: "post" | "put") =>
      (url: string, data: any = {}, params: any = {}) => {
        let toastId: number | string | undefined;
        const source = Axios.CancelToken.source();

        return this.axios({
          method,
          url,
          data,
          params,
          timeout: 0,
          cancelToken: source.token,
          onUploadProgress: (e: ProgressEvent) => {
            const progress = e.loaded / e.total;

            // check if we already displayed a toast
            if (toastId === undefined) {
              toastId = ADMIN.toast.default(
                <div className="flex jc-sb ai-c">
                  <span>Upload in Progress</span>
                  <Button
                    variant="contained"
                    size="small"
                    color="error"
                    onClick={() => source.cancel("Upload canceled")}
                  >
                    Cancel{" "}
                  </Button>
                </div>,
                {
                  progress: progress,
                  hideProgressBar: false,
                  closeOnClick: false,
                  className: "upload--progress__toast",
                  closeButton: false,
                  draggable: false,
                }
              );
            } else {
              ADMIN.toast.update(toastId, {
                progress: progress,
                hideProgressBar: false,
              });
            }
          },
        })
          .then((res) => {
            if (toastId) ADMIN.toast.dismiss(toastId);
            return res;
          })
          .catch((e) => {
            if (toastId) ADMIN.toast.dismiss(toastId);
            throw e;
          });
      };

  get = (
    url: string,
    params: any = {},
    timeout?: number,
    cancelToken?: CancelToken
  ) => {
    return this.axios({
      method: "get",
      url,
      params,
      timeout: timeout ?? this.timeout,
      cancelToken,
    });
  };

  post = (url: string, data: any = {}, params: any = {}, timeout?: number) => {
    return this.axios({
      method: "post",
      url,
      data,
      params,
      timeout: timeout ?? this.timeout,
    });
  };

  uploadPost = this.upload("post");

  put = (url: string, data: any, params: any = {}, timeout?: number) => {
    return this.axios({
      method: "put",
      url,
      data,
      params,
      timeout: timeout ?? this.timeout,
    });
  };
  patch = (url: string, data: any, params: any = {}, timeout?: number) => {
    return this.axios({
      method: "patch",
      url,
      data,
      params,
      timeout: timeout ?? this.timeout,
    });
  };

  uploadPut = this.upload("put");

  delete = (url: string, data?: any, timeout?: number) => {
    return this.axios({
      method: "delete",
      url,
      data,
      timeout: timeout ?? this.timeout,
    });
  };

  /**send login request */
  login = (credentials: any) => {
    return this.post("auth/login/", { ...credentials }, {}, 30000);
  };
}

const API = new api(apiBase);

export { API };